@charset "utf-8";
/* CSS Document */

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
}
@media screen and (max-width: 330px) {
}

/* +++++++++++++++++++++++++++++++++++++ */
/* ++++  header  +++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++ */

#header_01{
	height:100px;
	position:fixed;
	top:0; left:0;
	background:#fff;
	border-bottom:#e1e1e1 2px solid;
	width:100%;
	z-index:99;
}

#header_01 .inner_01{ position:relative;}

#header_01 .logo{
	position:absolute;
	top:0; left:35%;
}


#header_02{
	padding:0;
	background:#fff;
}


.banner_area_01{ margin:0 0 50px 0;}


@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
#header_01 .logo{ left:5%;}
.banner_area_01{ width:100%;}
.banner_area_01 img{ width:100%;}
}
@media screen and (max-width: 450px) {
#header_01{height:75px;}
#header_01 .logo{ top:20%; left:18%; width:40%;}
#header_01 img{ width:100%;}
#header_01 ul{ width:40%;}
#header_01 li{ width:33.33%;}
#header_02{	padding:75px 0 0 0;}
}

/*　カートアイコンに商品数追加　*/

.cart_icon_01{
	position:relative;
}
.cart_icon_01 .items_01{
    position: absolute;
    top: 12%;
    right: 2%;
    background: #e60012;
    border: #fff 3px solid;
    padding: 3px 8px;
    color: #fff;
    border-radius: 30px;
    z-index: 9;
}

@media screen and (max-width: 450px) {
.cart_icon_01 .items_01{
    top: 3%;
    right: 0%;
    padding: 2px 6px;
	font-size:10px;
}

}


/* +++++++++++++++++++++++++++++++++++++ */
/* ++++  hm  +++++++++++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++ */

.drawer-nav h2{
	font-weight:normal;
	font-size:24px;
	text-align:center;
	padding:30px 0 20px 0;
	margin:0;
}
.drawer-menu li{padding:0 10px;}
.drawer-menu li a{
	display:block;
	padding:10px 0;
	border-bottom:#ccc 1px solid;
	text-decoration:none;
	color:#333;
}


.drawer-menu li.lineup_01 h3{
	font-weight:normal;
	font-size:14px;
	padding:20px 0 0 0;
	margin:0;
}


.drawer-menu li.lineup_01 a,
.drawer-menu li.lineup_01 p{
	padding:2px 10px!important;
	margin:0!important;
	border-bottom:none!important;
}

.drawer-menu li.lineup_01{
	border-bottom:#ccc 1px solid;
	padding:0 0 10px 0;;
	margin:0 10px ;
}

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
.drawer-hamburger{ padding:25px!important;}
.drawer-nav{ width: 20.25rem!important;}
.drawer--left.drawer-open .drawer-hamburger{left: 19.25rem!important;}

.drawer-menu li a{padding:10px 0;}
.drawer-menu li.lineup_01 h3{padding:10px 0;}
.drawer-nav h2{padding:30px 0 10px 0;}

}
@media screen and (max-width: 330px) {

.drawer-nav{ width: 18.25rem!important;}
.drawer--left.drawer-open .drawer-hamburger{left: 17.25rem!important;}
}



